
/**
 * @name: 小程序管理-首页轮播图管理
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 小程序管理-首页轮播图管理
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {bannerCreateApi, bannerDetailApi, bannerModifyApi, bannerQueryApi, bannerRemoveApi} from "@/apis/applet/banner"
import { IAppletBanner } from "@/apis/applet/banner/types"
import config from "@/config";
import type { ICrudOption } from "@/types/m-ui-crud"
import {IPage} from "@/apis/page";

@Component({})
export default class appletBanner extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IAppletBanner[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IPage = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IAppletBanner> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    dialogWidth: '600px',
    column: [
      {
        label: "序号",
        type: "index",
        align: "center",
        width: 50,
        addHide: true,
        editHide: true
      },
      {
        label: "图片",
        prop: "pic",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
        rules: [
          {required: true, message: "图片不能为空", trigger: "blur"}
        ],
        addSlot: true,
        editSlot: true
      },
      {
        label: "排序",
        prop: "sort",
        align: "center",
        type: "number",
        rules: [
          {required: true, message: "排序不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!value || value == '0') {
                return callback(new Error('排序不能为空和0'))
              }
              if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
                return callback(new Error('排序必须为正整数'))
              }
              if (parseInt(value) > 99) {
                return callback(new Error('排序最大值99'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        label: "跳转商品",
        prop: "productName",
        align: "center",
        addHide: true,
        editHide: true,
        overHidden: true
      },
      {
        label: "跳转商品",
        prop: "productId",
        align: "center",
        hide: true,
        rules: [
          {required: true, message: "跳转商品不能为空", trigger: "blur"}
        ],
        // @ts-ignore
        filterable: true,
        type: "select",
        dicUrl: "/api/card/product/dropDown?status=1",
        dicFormatter: (res: any) => ({list: res.data, label: 'productName', value: 'productId'}),
      },
      {
        label: "状态",
        prop: "bannerStatus",
        align: "center",
        slot: true,
        addHide: true,
        editHide: true
      },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        addHide: true,
        editHide: true
      },
      {
        label: "更新时间",
        prop: "editTime",
        align: "center",
        addHide: true,
        editHide: true
      }
    ]
  }

  getList () {
    this.tableLoading = true
    bannerQueryApi(this.queryParam).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  beforeUpload (file: File) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 1MB!');
    }
    return isJPG && isLt2M;
  }

  rowSave (form: IAppletBanner, done: Function, loading: Function) {
    bannerCreateApi(form).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IAppletBanner, done: Function, loading: Function) {
    bannerModifyApi(form).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowDel (form: IAppletBanner, index: number) {
    bannerRemoveApi(form.bannerId).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
      }
    })
  }

  statusChange (bannerId: string, bannerStatus: number) {
    this.tableLoading = true
    bannerModifyApi({bannerId, bannerStatus}).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
      }
    }).finally(() => {
      this.tableLoading = false
    })
  }

  openEdit (row: IAppletBanner, index: number) {
    bannerDetailApi(row.bannerId).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
    })
  }

  created () {
    this.getList()
  }
}
