/**
 * @name: 小程序管理-首页轮播图管理接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 小程序管理-首页轮播图管理接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPage, IPageRes} from "@/apis/page";
import {IAppletBanner} from "@/apis/applet/banner/types";

/**
 * 分页查询
 * @param params
 */
export const bannerQueryApi = (params: IPage) => get<IPageRes<IAppletBanner[]>>("/card/banner/query", params)
/**
 * 修改
 * @param data
 */
export const bannerModifyApi = (data: Partial<IAppletBanner>) => postJ("/card/banner/modify", data)
/**
 * 创建
 * @param data
 */
export const bannerCreateApi = (data: Partial<IAppletBanner>) => postJ("/card/banner/create", data)
/**
 * 删除
 * @param id
 */
export const bannerRemoveApi = (id: string) => get("/card/banner/remove/" + id)
/**
 * 详情
 * @param id
 */
export const bannerDetailApi = (id: string) => get<IAppletBanner>("/card/banner/detail/" + id)
